import Page from '../components/page/page';
import Head from 'next/head';
import AggregatedView from '../views/aggregated-view/aggregated-view';
import { useIsMobile } from '../libs/is-mobile';
import SPAggregatedView from '../views/sp-aggregated-view/sp-aggregated-view';

export function Index() {
  const isMobile = useIsMobile();

  return <>
    <Head>
      <title>BRiSK 適時開示</title>
    </Head>
    <Page>
      {isMobile ? <SPAggregatedView/> : <AggregatedView/>}
    </Page>
  </>

}

export default Index;

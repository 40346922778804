import './saved-condition.module.scss';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import {
  Bookmark,
  BookmarkBorder,
  BookmarkOutlined,
  DeleteOutline,
  List,
  NotificationsActive,
  NotificationsActiveOutlined,
  NotificationsOffOutlined,
  Star,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useFilterLibrary } from '../../libs/filter-library';
import { useInstantFilter } from '../../libs/filter';
import FilterSummary from '../filter-summary/filter-summary';
import TedinetDialog from '../tedinet-dialog/tedinet-dialog';
import cloner from 'rfdc/default';

/* eslint-disable-next-line */
export interface SavedConditionProps {
  iconSize?: 'small';
}

export function SavedCondition(props: SavedConditionProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const filterLibrary = useFilterLibrary();

  const instantFilter = useInstantFilter();

  // 新規保存時に条件に名前をつける
  const [namingDialogOpen, setNamingDialogOpen] = useState(false);
  const [newName, setNewName] = useState('新規条件');
  const onClickSaveCurrent = () => {
    setNamingDialogOpen(true);
  };
  const onClickNamingOk = () => {
    if (filterLibrary.selected !== undefined) {
      filterLibrary.newFilter(
        newName,
        cloner(filterLibrary.filterDict[filterLibrary.selected].filter)
      );
    } else {
      filterLibrary.newFilter(newName, cloner(instantFilter.condition));
    }
    closeNamingDialog();
  };
  const closeNamingDialog = () => {
    setNamingDialogOpen(false);
    setNewName('新規条件');
  };

  // 削除
  const [deleteTarget, setDeleteTarget] = useState<number | null>(null);

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <BookmarkBorder
          style={{
            color: 'white',
            verticalAlign: 'middle',
            fontSize: props.iconSize === 'small' ? 24 : 30,
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Box
            height={500}
            p={2}
            sx={{ overflowY: 'scroll', minWidth: 300, maxWidth: '100vw' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flexGrow: 1, mr: 2 }}>
                保存済みの検索条件一覧
              </Typography>
              <Button
                variant={'outlined'}
                onClick={() => onClickSaveCurrent()}
                size={'small'}
                sx={{ flexGrow: 0 }}
                disabled={filterLibrary.selected !== undefined}
              >
                現在の条件を保存する
              </Button>
            </Box>
            <Box mt={2}>
              {filterLibrary.filters.map((f) => (
                <Card
                  key={f.id}
                  onClick={() =>
                    filterLibrary.selected === f.id
                      ? filterLibrary.setSelected()
                      : filterLibrary.setSelected(f.id)
                  }
                  sx={{
                    mb: 1,
                    border:
                      f.id === filterLibrary.selected
                        ? '2px solid #aaa'
                        : 'none',
                    maxWidth: 450,
                  }}
                >
                  <CardContent>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item>
                        <Typography>{f.name}</Typography>
                        <Typography variant={'body2'}>
                          本日
                          {filterLibrary.filterStats[f.id]?.dailyCount ?? '-'}件
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            filterLibrary.setFilter(f.id, {
                              ...f,
                              notification: !f.notification,
                            });
                          }}
                        >
                          {f.notification ? (
                            <NotificationsActiveOutlined />
                          ) : (
                            <NotificationsOffOutlined />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteTarget(f.id);
                          }}
                          disabled={filterLibrary.selected === f.id}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Grid container justifyContent={'space-between'}>
                        <Grid item>
                          <FilterSummary
                            filter={f.filter}
                            updatePartial={filterLibrary.updatePartial(f.id)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Paper>
      </Popover>

      <TedinetDialog
        open={namingDialogOpen}
        onClose={() => closeNamingDialog()}
        title={'条件を保存する'}
        actions={[
          { label: 'キャンセル', onClick: () => closeNamingDialog() },
          { label: 'OK', onClick: () => onClickNamingOk() },
        ]}
      >
        <TextField
          value={newName}
          onChange={(e) => setNewName(e.currentTarget.value)}
          focused
        />
      </TedinetDialog>

      <TedinetDialog
        open={!!deleteTarget}
        onClose={() => setDeleteTarget(null)}
        title={`保存済み条件「${filterLibrary.filterDict[deleteTarget]?.name}」を削除します`}
        actions={[
          { label: 'キャンセル', onClick: () => setDeleteTarget(null) },
          {
            label: 'OK',
            onClick: () => {
              filterLibrary.deleteFilter(deleteTarget);
              setDeleteTarget(null);
            },
          },
        ]}
      >
        <Typography>
          削除した条件は復元することができませんがよろしいですか？
        </Typography>
      </TedinetDialog>
    </>
  );
}

export default SavedCondition;

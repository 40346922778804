import './watchlist-selector.module.scss';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { Watchlist } from '../../libs/watchlist';
import { Close } from '@mui/icons-material';
import WatchlistDialog from '../watchlist/watchlist-dialog';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface WatchlistSelectorProps {
  watchlists: Watchlist[];
  selected?: number[];
  onSelect: (watchlist: Watchlist) => void;
  onRemove?: (watchlist: Watchlist) => void;
  border?: boolean;
  showRemover?: boolean;
  height?: number;
  label?: string;
  showEdit?: boolean;
}

export function WatchlistSelector(props: WatchlistSelectorProps) {
  const [open, setOpen] = useState(false);
  return (
    <FormControl fullWidth>
      {props.label !== '' && (
        <FormLabel> {props.label ?? '銘柄リスト'}</FormLabel>
      )}
      <Box sx={{ border: props.border && '1px solid #eee', borderRadius: 1 }}>
        <List sx={{ height: props.height ?? 300, overflowY: 'scroll' }}>
          {props.watchlists.map((w) => {
            return (
              <ListItem
                key={w.id}
                disablePadding
                secondaryAction={
                  props.showRemover && (
                    <IconButton onClick={() => props.onRemove(w)}>
                      <Close />
                    </IconButton>
                  )
                }
              >
                <ListItemButton
                  onClick={() => props.onSelect(w)}
                  selected={
                    props.selected?.length > 0 && props.selected.includes(w.id)
                  }
                >
                  <Typography>{w.name}</Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <WatchlistDialog open={open} onClose={() => setOpen(false)} />
      {props.showEdit && <Button onClick={() => setOpen(true)}>編集</Button>}
    </FormControl>
  );
}

export default WatchlistSelector;

import './sp-aggregated-view.module.scss';
import {
  useDisclosureFilterGenerator,
  useInstantFilter,
} from '../../libs/filter';
import { useEffect, useState } from 'react';
import { FullyJoinedDisclosure } from '@tedinet/data-access-disclosure';
import FilterSummary from '../../components/filter-summary/filter-summary';
import dayjs from 'dayjs';
import { useRealtime } from '../../libs/realtime';
import GmailFilter from '../../components/gmail-filter/gmail-filter';
import { useServerStatus } from '../../libs/server-status';
import { useFilterLibrary } from '../../libs/filter-library';
import DateRangeSelector from '../../components/date-range-selector/date-range-selector';
import { useRange } from '../../libs/range';
import { Box, CircularProgress, Typography } from '@mui/material';
import DisclosureCardList from '../../components/disclosure-card-list/disclosure-card-list';
import Connectivity from '../../components/connectivity/connectivity';
import { useAPI } from '../../libs/use-api';

/* eslint-disable-next-line */
export interface SPAggregatedViewProps {}

export function SPAggregatedView(props: SPAggregatedViewProps) {
  const {
    disclosuresFilter,
    condition: instantFilterCondition,
    updatePartial: updatePartialInstantFilter,
  } = useInstantFilter();
  const serverStatus = useServerStatus();
  const { type: rangeType, day: rangeDay, dateStart, dateEnd } = useRange();
  const { disclosures, initialized } = useRealtime();
  const [oldDisclosures, setOldDisclosures] = useState<FullyJoinedDisclosure[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const filterLibrary = useFilterLibrary();
  const filterGenerator = useDisclosureFilterGenerator();
  const { getDisclosures } = useAPI();

  useEffect(() => {
    if (
      !serverStatus ||
      (dateStart === rangeDay &&
        dateEnd === rangeDay &&
        rangeDay === serverStatus.date)
    )
      return;
    setLoading(true);
    getDisclosures({
      dateStart,
      dateEnd:
        dateEnd >= serverStatus.date
          ? dayjs(serverStatus.date).subtract(1, 'day').format('YYYY-MM-DD')
          : dateEnd,
    }).then(([d]) => {
      setOldDisclosures(d);
      setLoading(false);
    });
  }, [rangeDay, dateStart, dateEnd]);

  const selectedFilterCondition =
    filterLibrary.filterDict[filterLibrary.selected];
  const disclosureFilter = filterLibrary.selected
    ? filterGenerator(filterLibrary.filterDict[filterLibrary.selected].filter)
    : disclosuresFilter;
  const updatePartial = filterLibrary.selected
    ? filterLibrary.updatePartial(filterLibrary.selected)
    : updatePartialInstantFilter;
  const filterCondition =
    selectedFilterCondition?.filter ?? instantFilterCondition;

  const disclosureData = (() => {
    if (!serverStatus) return [];
    switch (rangeType) {
      case 'daily':
        if (rangeDay === serverStatus.date) {
          return Object.values(disclosures).reverse();
        } else {
          return [...oldDisclosures].reverse();
        }
      case 'weekly':
        if (dateStart <= serverStatus.date && serverStatus.date <= dateEnd) {
          return [...oldDisclosures, ...Object.values(disclosures)].reverse();
        } else {
          return [...oldDisclosures].reverse();
        }
      default:
        return [...oldDisclosures].reverse();
    }
  })();

  const filteredDisclosures = disclosureFilter(disclosureData);

  return (
    <>
      <DateRangeSelector />
      <GmailFilter
        filterCondition={filterCondition}
        updatePartial={updatePartial}
      />
      <FilterSummary
        deletable
        fillIfEmpty
        filter={filterCondition}
        updatePartial={updatePartial}
      />
      {loading || !initialized ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant={'body2'}>
            {filteredDisclosures.length}件
          </Typography>
          <DisclosureCardList disclosures={filteredDisclosures} />
        </>
      )}
    </>
  );
}

export default SPAggregatedView;

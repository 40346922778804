import './disclosure-card-list.module.scss';
import { FullyJoinedDisclosure } from '@tedinet/data-access-disclosure';
import { Virtuoso } from 'react-virtuoso';
import DisclosureCard from '../disclosure-card/disclosure-card';

/* eslint-disable-next-line */
export interface DisclosureCardListProps {
  disclosures: FullyJoinedDisclosure[];
}

export function DisclosureCardList(props: DisclosureCardListProps) {
  return (
    <>
      <Virtuoso
        style={{ marginBottom: 60 }}
        useWindowScroll
        overscan={100}
        data={props.disclosures}
        itemContent={(_, disclosure) => (
          <DisclosureCard disclosure={disclosure} />
        )}
      />
    </>
  );
}

export default DisclosureCardList;

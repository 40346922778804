import './app-bar.module.scss';
import {
  AppBar as MUIAppBar,
  Box,
  Container,
  Toolbar,
  Typography,
} from '@mui/material';
import SavedCondition from '../saved-condition/saved-condition';
import { useIsMobile } from '../../libs/is-mobile';
import Connectivity from '../connectivity/connectivity';

/* eslint-disable-next-line */
export interface AppBarProps {}

export function AppBar(props: AppBarProps) {
  const isMobile = useIsMobile();
  return (
    <Box mb={1}>
      <MUIAppBar position="fixed" sx={{ height: 40 }}>
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', height: 40, minHeight: 40 }}>
            <Typography
              variant="h1"
              noWrap
              sx={{ flexGrow: 1, fontSize: isMobile ? 18 : 20, mt: 1 }}
            >
              BRiSK 適時開示
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <Box my={1} px={1}>
                  <Connectivity />
                </Box>
                <Box>
                  <SavedCondition iconSize="small" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </MUIAppBar>
      <Box sx={{ height: 40 }} />
    </Box>
  );
}

export default AppBar;

import './industry-chips.module.scss';
import { useInstantFilter } from '../../libs/filter';
import {
  blue,
  brown,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { Chip } from '@mui/material';
import { industryCodeMap } from '@tedinet/data-access-stock';

/* eslint-disable-next-line */
export interface IndustryChipsProps {
  industry33Code: number;
  filterOnClick?: boolean;
  xs?: boolean;
  prefix?: string;
}

export function IndustryChips(props: IndustryChipsProps) {
  const filter = useInstantFilter();

  const industry = industryCodeMap[props.industry33Code];
  if (!industry) return <></>;

  const { industry17, industry33 } = industryCodeMap[props.industry33Code];

  const colors = [
    red,
    purple,
    indigo,
    blue,
    green,
    orange,
    brown,
    lime,
    teal,
    pink,
  ];
  const shades = [500, 200];

  const colorMap: string[] = [];
  for (let i = 0; i < 20; i++) {
    colorMap.push(colors[i % 10][shades[Math.floor(i / 10)]]);
  }

  return (
    <>
      {/*{industry17.children.length === 1 && <Chip label={industry33.name} size={'small'} onClick={props.filterOnClick ? () => filter.setStockCondition({...filter.condition.stock, industry: {...filter.condition.stock.industry, [industry33.code]: true}}) : undefined}/>}*/}
      {/*{industry17.children.length !== 1 && <Chip label={`${industry17.name} > ${industry33.name}`} size={'small'} onClick={props.filterOnClick ? () => filter.setStockCondition({...filter.condition.stock, industry: {...filter.condition.stock.industry, [industry33.code]: true}}) : undefined}/>}*/}
      <Chip
        label={`${props.prefix ?? ''}${industry17.name}`}
        size={props.xs ? undefined : 'small'}
        sx={props.xs ? { height: 18 } : {}}
        onClick={
          props.filterOnClick
            ? () => {
                const childCodes = industry17.children.map((c) => c.code);
                for (const k in filter.condition.stock.industry) {
                  const code = parseInt(k);
                  filter.condition.stock.industry[k] =
                    childCodes.includes(code);
                }
                filter.setStockCondition({ ...filter.condition.stock });
              }
            : undefined
        }
      />
    </>
  );
}

export default IndustryChips;

import { FullyJoinedDisclosureVersion } from './disclosure-version';
import { FullyJoinedDisclosure } from './disclosure';
import { DisclosureTagInterface } from './tag';
import { SuccessAPIResponse } from '@tedinet/data-access-app';
import {
  IsArray,
  IsDateString,
  IsInt,
  IsOptional,
  IsString,
} from 'class-validator';

export class GetDisclosureVersionsRequest {
  @IsDateString()
  date: string;
}
export type GetDisclosureVersionsResponse = SuccessAPIResponse<
  FullyJoinedDisclosureVersion[]
>;

export type GetDisclosureTagsRequest = Record<string, never>;
export type GetDisclosureTagsResponse = SuccessAPIResponse<
  DisclosureTagInterface[]
>;

export class GetDisclosuresRequest {
  @IsDateString()
  @IsOptional()
  date?: string;

  @IsDateString()
  @IsOptional()
  dateStart?: string;

  @IsDateString()
  @IsOptional()
  dateEnd?: string;

  @IsInt()
  @IsOptional()
  issueCode?: string;

  @IsInt({ each: true })
  @IsOptional()
  issueCodes?: string[];

  @IsString()
  @IsOptional()
  disclosureTitle?: string;

  @IsInt({ each: true })
  @IsOptional()
  disclosureTags?: number[];

  @IsInt()
  @IsOptional()
  count?: number;

  @IsInt()
  @IsOptional()
  skip?: number;
}
export type GetDisclosuresResponse = SuccessAPIResponse<
  FullyJoinedDisclosure[]
> & {
  count: number;
};

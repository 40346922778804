import './gmail-filter.module.scss';
import { Box, Button, Grid, Popover, TextField } from '@mui/material';
import { UpdatePartial } from '../../libs/filter';
import MarketCapitalFilter from '../market-capital-filter/market-capital-filter';
import { FilterCondition, IndustryCondition } from '../../libs/condition';
import WatchlistSelector from '../watchlist-selector/watchlist-selector';
import IndustrySelector from '../industry-selector/industry-selector';
import TagSelector from '../tag-selector/tag-selector';
import { useEffect, useRef, useState } from 'react';
import { useWatchlist } from '../../libs/watchlist';
import { useIsMobile } from '../../libs/is-mobile';

/* eslint-disable-next-line */
export interface FilterProps {
  filterCondition: FilterCondition;
  updatePartial: UpdatePartial;
}

export function GmailFilter(props: FilterProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [opening, setOpening] = useState<
    'watchlist' | 'marketCapital' | 'industry' | 'tag' | null
  >(null);
  const keywordRef = useRef(null);

  useEffect(() => {
    if (window && keywordRef) {
      const onCtrlF = (e) => {
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.code === 'KeyF' || e.keyCode === 70 || e.which === 70)
        ) {
          e.preventDefault();
          keywordRef.current.focus();
        }
      };
      window.addEventListener('keydown', onCtrlF);

      return () => window.removeEventListener('keydown', onCtrlF);
    }
  }, [keywordRef]);
  const isMobile = useIsMobile();

  const handleClick = (e, type) => {
    setAnchorEl(e.currentTarget);
    setOpening(type);
  };

  const { watchlists } = useWatchlist();
  const buttonSize: 'small' | 'medium' | 'large' = isMobile
    ? 'medium'
    : 'large';

  return (
    <Box my={1}>
      <Grid container spacing={1}>
        <Grid item lg={4} xs={8}>
          <TextField
            size={'small'}
            label={'キーワード'}
            placeholder={'「ファーストリテイリング」「治験」など'}
            fullWidth
            value={props.filterCondition.global.keyword}
            onChange={(e) =>
              props.updatePartial({
                global: { keyword: e.currentTarget.value },
              })
            }
            inputRef={keywordRef}
            inputProps={{
              style: isMobile ? { height: 16 } : undefined,
            }}
          />
        </Grid>
        <Grid item lg={2} xs={4}>
          <Button
            variant={'outlined'}
            fullWidth
            size={buttonSize}
            onClick={(e) => handleClick(e, 'watchlist')}
          >
            銘柄リスト
          </Button>
          <Popover
            open={opening === 'watchlist' && !!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Box p={2} width={250}>
              <WatchlistSelector
                watchlists={watchlists}
                onSelect={(w) =>
                  props.updatePartial({
                    stock: {
                      watchlists:
                        props.filterCondition.stock.watchlists.includes(w.id)
                          ? props.filterCondition.stock.watchlists.filter(
                              (fw) => fw !== w.id
                            )
                          : [...props.filterCondition.stock.watchlists, w.id],
                    },
                  })
                }
                showEdit
                selected={props.filterCondition.stock.watchlists}
                border
              />
            </Box>
          </Popover>
        </Grid>
        <Grid item lg={2} xs={4}>
          <Button
            variant={'outlined'}
            fullWidth
            size={buttonSize}
            onClick={(e) => handleClick(e, 'marketCapital')}
          >
            時価総額
          </Button>
          <Popover
            open={opening === 'marketCapital' && !!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Box p={2}>
              <MarketCapitalFilter
                state={props.filterCondition.stock.marketCapital}
                onChange={(c) =>
                  props.updatePartial({ stock: { marketCapital: c } })
                }
              />
            </Box>
          </Popover>
        </Grid>
        <Grid item lg={2} xs={4}>
          <Button
            variant={'outlined'}
            fullWidth
            size={buttonSize}
            onClick={(e) => handleClick(e, 'industry')}
          >
            業種
          </Button>
          <Popover
            open={opening === 'industry' && !!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Box p={2}>
              <IndustrySelector
                industryCondition={props.filterCondition.stock.industry}
                setIndustryCondition={(c: IndustryCondition) =>
                  props.updatePartial({ stock: { industry: c } })
                }
              />
            </Box>
          </Popover>
        </Grid>
        <Grid item lg={2} xs={4}>
          <Button
            variant={'outlined'}
            fullWidth
            size={buttonSize}
            onClick={(e) => handleClick(e, 'tag')}
          >
            タグ
          </Button>
          <Popover
            open={opening === 'tag' && !!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Box p={2}>
              <TagSelector
                condition={props.filterCondition.disclosure.tagIDs}
                setCondition={(v) =>
                  props.updatePartial({ disclosure: { tagIDs: v } })
                }
              />
            </Box>
          </Popover>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GmailFilter;

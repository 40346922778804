import { Watchlist } from './watchlist';

export interface GlobalCondition {
  keyword: string;
}

export interface MarketCapitalCondition {
  v50: boolean;
  v50_100: boolean;
  v100_300: boolean;
  v300_1k: boolean;
  v1k_3k: boolean;
  v3k: boolean;
}

export const MarketCapitalConditionBoundary: Record<
  keyof MarketCapitalCondition,
  [number, number]
> = {
  v50: [0, 50],
  v50_100: [50, 100],
  v100_300: [100, 300],
  v300_1k: [300, 1000],
  v1k_3k: [1000, 3000],
  v3k: [3000, Infinity],
};

export const MapMarketCapitalString: Record<
  keyof MarketCapitalCondition,
  string
> = {
  v50: '50億円未満',
  v50_100: '50億円以上100億円未満',
  v100_300: '100億円以上300億円未満',
  v300_1k: '300億円以上1000億円未満',
  v1k_3k: '1000億円以上3000億円未満',
  v3k: '3000億円以上',
};

export type IndustryCondition = Record<number, boolean>;

export interface StockCondition {
  keyword: string;
  issueCodes: string[];
  watchlists: number[];
  marketCapital: MarketCapitalCondition;
  industry: IndustryCondition;
}

export interface DisclosureCondition {
  keyword: string;
  tagIDs: number[];
  disclosureCodes: string[] | null;
}

export interface FilterCondition {
  global: GlobalCondition;

  stock: StockCondition;

  disclosure: DisclosureCondition;
}

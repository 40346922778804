import './tag-selector.module.scss';
import {
  Box,
  Button,
  Checkbox, Collapse, FormControl,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTags } from '../../libs/tags';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface TagSelectorProps {
  condition: number[];
  setCondition: (condition: number[]) => void;
  border?: boolean;
}

export function TagSelector(props: TagSelectorProps) {
  const {parents, children} = useTags()

  const [open, setOpen] = useState<Record<string, boolean>>(Object.fromEntries(parents.map(t => [t.id, false])));

  const setCondition = (diff: Record<number, boolean>) => {
    const set = new Set<number>(props.condition);
    Object.keys(diff).forEach(k => {
      if (diff[k]) set.add(parseInt(k));
      else set.delete(parseInt(k));
    })
    props.setCondition(Array.from(set));
  }

  const allIndustryChecked = Array.from(new Set(props.condition)).length === children.length;

  const changeAll = () => {
    props.setCondition(allIndustryChecked ? [] : children.map(c => c.id));
  }

  return <FormControl fullWidth>
      <FormLabel>タグ</FormLabel>
    <Box sx={{border: props.border && '1px solid #eee', borderRadius: 1}}>
      <List sx={{height: 300, overflowY: 'scroll'}}>
        {parents.map(t => {
          const allChecked = t.children.length > 0 ? t.children.every(c => props.condition.includes(c.id)) : props.condition.includes(t.id);
          return <div key={t.name}><ListItem key={t.name} secondaryAction={t.children.length > 1 && <IconButton onClick={() => setOpen(p => ({...p, [t.name]: !p[t.name]}))}>{open[t.name] ? <ExpandLess/> : <ExpandMore/>}</IconButton>}
                                             disablePadding>
            <ListItemButton onClick={() => {
              t.children.length > 0 ? setCondition(Object.fromEntries(t.children.map(c => [c.id, !allChecked]))) : setCondition({[t.id]: !allChecked})
            }}
                            dense>
              <ListItemIcon>
                <Checkbox checked={allChecked} indeterminate={!allChecked && t.children.some(c => props.condition.includes(c.id))}/>
              </ListItemIcon>
              <ListItemText primary={t.name} />
            </ListItemButton>
          </ListItem>
            {t.children.length > 1 && <Collapse in={open[t.name]}>
              <List>
                {t.children.map(c => {
                  const checked = props.condition.includes(c.id)
                  return <ListItem key={c.id}>
                    <ListItemButton onClick={() => setCondition({ [c.id]: !checked })} dense>
                      <ListItemIcon>
                        <Checkbox checked={checked} />
                      </ListItemIcon>
                      <ListItemText primary={c.name} />
                    </ListItemButton>
                  </ListItem>;
                })}
              </List>
            </Collapse>}</div>;
        })}
      </List>
      <Button onClick={() => changeAll()}>{allIndustryChecked ? '全てのチェックを外す' : '全てをチェックする'}</Button>
    </Box>
    </FormControl>;
}

export default TagSelector;

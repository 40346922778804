import './industry-selector.module.scss';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { industries } from '@tedinet/data-access-stock';
import { IndustryCondition } from '../../libs/condition';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React from 'react';

/* eslint-disable-next-line */
export interface IndustrySelectorProps {
  industryCondition: IndustryCondition;
  setIndustryCondition: (condition: IndustryCondition) => void;
  border?: boolean;
}

export function IndustrySelector(props: IndustrySelectorProps) {
  const setValue = (data: Record<number, boolean>) => {
    props.setIndustryCondition({
      ...props.industryCondition,
      ...data,
    });
  };

  const [open, setOpen] = useState<Record<string, boolean>>(
    Object.fromEntries(industries.map((i) => [i.name, false]))
  );

  const allIndustryChecked = Object.values(props.industryCondition).every(
    (c) => c
  );

  const changeAll = () => {
    props.setIndustryCondition(
      Object.fromEntries(
        Object.keys(props.industryCondition).map((k) => [
          k,
          !allIndustryChecked,
        ])
      )
    );
  };

  return (
    <FormControl fullWidth>
      <FormLabel>業種</FormLabel>
      <Box sx={{ border: props.border && '1px solid #eee', borderRadius: 1 }}>
        <List sx={{ height: 300, overflowY: 'scroll' }}>
          {industries.map((i) => {
            const allChecked = i.children.every(
              (c) => props.industryCondition[c.code]
            );
            return (
              <React.Fragment key={i.name}>
                <ListItem
                  key={i.name}
                  secondaryAction={
                    i.children.length > 1 && (
                      <IconButton
                        onClick={() =>
                          setOpen((p) => ({ ...p, [i.name]: !p[i.name] }))
                        }
                      >
                        {open[i.name] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    )
                  }
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => {
                      setValue(
                        Object.fromEntries(
                          i.children.map((c) => [c.code, !allChecked])
                        )
                      );
                    }}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={i.children.every(
                          (c) => props.industryCondition[c.code]
                        )}
                        indeterminate={
                          !i.children.every(
                            (c) => props.industryCondition[c.code]
                          ) &&
                          i.children.some(
                            (c) => props.industryCondition[c.code]
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={i.name} />
                  </ListItemButton>
                </ListItem>
                {i.children.length > 1 && (
                  <Collapse in={open[i.name]} key={`${i.name}-collapse`}>
                    <List>
                      {i.children.map((c) => (
                        <ListItem key={c.code}>
                          <ListItemButton
                            onClick={() =>
                              setValue({
                                [c.code]: !props.industryCondition[c.code],
                              })
                            }
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={props.industryCondition[c.code]}
                              />
                            </ListItemIcon>
                            <ListItemText primary={c.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
        <Button onClick={() => changeAll()}>
          {allIndustryChecked ? '全てのチェックを外す' : '全てをチェックする'}
        </Button>
      </Box>
    </FormControl>
  );
}

export default IndustrySelector;

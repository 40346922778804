import './aggregated-view.module.scss';
import {
  useDisclosureFilterGenerator,
  useFilter,
  useInstantFilter,
} from '../../libs/filter';
import { useEffect, useState } from 'react';
import { FullyJoinedDisclosure } from '@tedinet/data-access-disclosure';
import TedinetTable, {
  Column,
} from '../../components/tedinet-table/tedinet-table';
import { FileLink } from '../../components/tedinet-table/fileLink';
import { useStocks } from '../../libs/stock';
import FilterSummary from '../../components/filter-summary/filter-summary';
import dayjs, { Dayjs } from 'dayjs';
import { useRealtime } from '../../libs/realtime';
import GmailFilter from '../../components/gmail-filter/gmail-filter';
import TagChips from '../../components/tag-chips/tag-chips';
import { useServerStatus } from '../../libs/server-status';
import IndustryChips from '../../components/industry-chips/industry-chips';
import { useFilterLibrary } from '../../libs/filter-library';
import DateRangeSelector from '../../components/date-range-selector/date-range-selector';
import { useRange } from '../../libs/range';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAPI } from '../../libs/use-api';
import Connectivity from '../../components/connectivity/connectivity';

/* eslint-disable-next-line */
export interface AggregatedViewProps {}

export function AggregatedView(props: AggregatedViewProps) {
  const {
    disclosuresFilter,
    condition: instantFilterCondition,
    updatePartial: updatePartialInstantFilter,
  } = useInstantFilter();
  const { dict: stockDict, lastAvailableDict } = useStocks();
  const serverStatus = useServerStatus();
  const { type: rangeType, day: rangeDay, dateStart, dateEnd } = useRange();
  const { getDisclosures } = useAPI();

  const columns: Column<FullyJoinedDisclosure>[] = [
    {
      id: 'time',
      title: '日時',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      valueGetter: (data) =>
        `${data.date} ${data.latestDisclosureVersion.time.slice(0, 5)}`,
    },
    {
      id: 'code',
      title: 'コード',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      valueGetter: (data) => data.issueCode,
      formatter: (v: string) => (v.slice(-1) === '0' ? v.slice(0, 4) : v),
    },
    {
      id: 'stockName',
      title: '社名',
      align: 'left',
      headerAlign: 'left',
      width: 160,
      valueGetter: (data) =>
        (
          stockDict[data.issueCode.toString().slice(0, 4)] ??
          lastAvailableDict[data.issueCode.toString().slice(0, 4)]
        )?.name ?? '(名称取得失敗)',
    },
    {
      id: 'title',
      title: 'タイトル',
      headerAlign: 'left',
      align: 'left',
      width: 558,
      valueGetter: (data) => data.latestDisclosureVersion.title,
      formatter: (v, data) => (
        <FileLink version={data.latestDisclosureVersion} />
      ),
    },
    {
      id: 'marketCapital',
      title: '時価総額 (億円)',
      align: 'right',
      headerAlign: 'right',
      width: 120,
      valueGetter: (data) =>
        (
          stockDict[data.issueCode.toString().slice(0, 4)] ??
          lastAvailableDict[data.issueCode.toString().slice(0, 4)]
        )?.marketCapital ?? '(時価総額不明)',
      formatter: (v) =>
        ((v as number) / 1e8).toLocaleString(undefined, {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        }),
    },
    {
      id: 'industry',
      title: '業種',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      valueGetter: (data) =>
        (
          stockDict[data.issueCode.toString().slice(0, 4)] ??
          lastAvailableDict[data.issueCode.toString().slice(0, 4)]
        )?.industry33Code,
      formatter: (v) =>
        v ? (
          <IndustryChips industry33Code={v as number} filterOnClick />
        ) : (
          '(時価総額不明)'
        ),
    },
    {
      id: 'category',
      title: 'タグ',
      align: 'left',
      headerAlign: 'left',
      width: 270,
      valueGetter: (data) => data.latestDisclosureVersion.tagIDs,
      formatter: (v) => <TagChips tagIDs={v as number[]} filterOnClick />,
    },
  ];
  const { disclosures, initialized } = useRealtime();
  const [oldDisclosures, setOldDisclosures] = useState<FullyJoinedDisclosure[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const filterLibrary = useFilterLibrary();
  const filterGenerator = useDisclosureFilterGenerator();

  useEffect(() => {
    if (
      !serverStatus?.date ||
      (dateStart === rangeDay &&
        dateEnd === rangeDay &&
        rangeDay === serverStatus.date)
    )
      return;
    setLoading(true);
    console.log(rangeDay, dateStart, dateEnd);
    getDisclosures({
      dateStart,
      dateEnd:
        dateEnd >= serverStatus.date
          ? dayjs(serverStatus.date).subtract(1, 'day').format('YYYY-MM-DD')
          : dateEnd,
    }).then(([d]) => {
      setOldDisclosures(d);
      setLoading(false);
    });
  }, [rangeDay, dateStart, dateEnd]);

  const selectedFilterCondition =
    filterLibrary.filterDict[filterLibrary.selected];
  const disclosureFilter = filterLibrary.selected
    ? filterGenerator(filterLibrary.filterDict[filterLibrary.selected].filter)
    : disclosuresFilter;
  const updatePartial = filterLibrary.selected
    ? filterLibrary.updatePartial(filterLibrary.selected)
    : updatePartialInstantFilter;
  const filterCondition =
    selectedFilterCondition?.filter ?? instantFilterCondition;

  const disclosureData = (() => {
    if (!serverStatus) return [];
    switch (rangeType) {
      case 'daily':
        if (rangeDay === serverStatus.date) {
          return Object.values(disclosures).reverse();
        } else {
          return [...oldDisclosures].reverse();
        }
      case 'weekly':
        if (dateStart <= serverStatus.date && serverStatus.date <= dateEnd) {
          return [...oldDisclosures, ...Object.values(disclosures)].reverse();
        } else {
          return [...oldDisclosures].reverse();
        }
      default:
        return [...oldDisclosures].reverse();
    }
  })();

  return (
    <>
      <DateRangeSelector />
      <GmailFilter
        filterCondition={filterCondition}
        updatePartial={updatePartial}
      />
      <FilterSummary
        deletable
        fillIfEmpty
        filter={filterCondition}
        updatePartial={updatePartial}
      />
      {loading || !initialized ? (
        <CircularProgress />
      ) : (
        <TedinetTable
          data={disclosureFilter(disclosureData)}
          columns={columns}
          idColumn={'disclosureID'}
          direction={'DESC'}
        />
      )}
    </>
  );
}

export default AggregatedView;

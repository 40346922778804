import './watchlist-dialog.module.scss';
import { createWatchlist, useWatchlist } from '../../libs/watchlist';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import WatchlistSelector from '../watchlist-selector/watchlist-selector';
import SearchStock from '../search-stock/search-stock';
import { useState } from 'react';
import { useStocks } from '../../libs/stock';
import { Close } from '@mui/icons-material';

/* eslint-disable-next-line */
export interface WatchlistProps {
  open: boolean;
  onClose: () => void;
}

export function WatchlistDialog(props: WatchlistProps) {
  const {
    watchlists,
    updateWatchlist,
    dict: watchlistDict,
    removeWatchlist,
  } = useWatchlist();
  const [currentWatchlist, setCurrentWatchlist] = useState<number | null>(
    watchlists[0]?.id ?? null
  );
  const { dict, lastAvailableDict } = useStocks();
  const watchlist = watchlistDict[currentWatchlist];
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth>
        <DialogTitle>銘柄リスト</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <WatchlistSelector
                watchlists={watchlists}
                onSelect={(w) => setCurrentWatchlist(w.id)}
                onRemove={(w) => {
                  if (removeWatchlist(w)) setCurrentWatchlist(null);
                }}
                selected={[currentWatchlist]}
                border
                showRemover
              />
              <Button
                fullWidth
                variant={'outlined'}
                onClick={() =>
                  updateWatchlist(createWatchlist('新規銘柄リスト'))
                }
              >
                追加
              </Button>
            </Grid>
            <Grid item lg={6}>
              <Box>
                <FormLabel>名前</FormLabel>
                <TextField
                  value={watchlistDict[currentWatchlist]?.name}
                  onChange={(e) =>
                    updateWatchlist({
                      ...watchlistDict[currentWatchlist],
                      name: e.currentTarget.value,
                    })
                  }
                  variant={'standard'}
                  size={'small'}
                  fullWidth
                  disabled={!watchlist}
                />
              </Box>

              <Box my={1}>
                <FormLabel>登録銘柄</FormLabel>
                <Box sx={{ border: '1px solid #eee', borderRadius: 1 }}>
                  <List sx={{ height: 190, overflowY: 'scroll' }}>
                    {watchlistDict[currentWatchlist]?.issueCodes.map((i) => {
                      return (
                        <ListItem
                          key={i}
                          secondaryAction={
                            <IconButton
                              onClick={() =>
                                updateWatchlist({
                                  ...watchlistDict[currentWatchlist],
                                  issueCodes: (
                                    watchlistDict[currentWatchlist]
                                      ?.issueCodes ?? []
                                  ).filter((ic) => ic !== i),
                                })
                              }
                            >
                              <Close />
                            </IconButton>
                          }
                        >
                          <Typography>
                            {i}{' '}
                            {(dict[i] ?? lastAvailableDict[i])?.name ??
                              '(名称取得失敗)'}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Box>
              <SearchStock
                onConfirm={(s) =>
                  updateWatchlist({
                    ...watchlistDict[currentWatchlist],
                    issueCodes: [
                      ...(watchlistDict[currentWatchlist]?.issueCodes ?? []),
                      s.issueCode,
                    ],
                  })
                }
                label={'追加'}
                disabled={currentWatchlist === null}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WatchlistDialog;

import './market-capital-filter.module.scss';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Select,
} from '@mui/material';
import {
  MapMarketCapitalString,
  MarketCapitalCondition,
} from '../../libs/condition';

/* eslint-disable-next-line */
export interface MarketCapitalFilterProps {
  state: MarketCapitalCondition;
  onChange: (selection: MarketCapitalCondition) => void;
}

export function MarketCapitalFilter(props: MarketCapitalFilterProps) {
  const allChecked = Object.values(props.state).every((s) => s);
  const onClickChangeAll = () => {
    if (!allChecked) {
      props.onChange(
        Object.fromEntries(
          Object.keys(props.state).map((k) => [k, true])
        ) as unknown as MarketCapitalCondition
      );
    } else {
      props.onChange(
        Object.fromEntries(
          Object.keys(props.state).map((k) => [k, false])
        ) as unknown as MarketCapitalCondition
      );
    }
  };
  return (
    <FormControl fullWidth>
      <FormLabel>時価総額</FormLabel>
      <FormGroup>
        {Object.entries(MapMarketCapitalString).map(([k, v]) => {
          return (
            <FormControlLabel
              key={k}
              control={
                <Checkbox
                  checked={props.state[k]}
                  onChange={(e) =>
                    props.onChange({ ...props.state, [k]: e.target.checked })
                  }
                />
              }
              label={v}
            />
          );
        })}
      </FormGroup>
      <Button onClick={() => onClickChangeAll()} fullWidth>
        {allChecked ? '全てのチェックを外す' : '全てをチェックする'}
      </Button>
    </FormControl>
  );
}

export default MarketCapitalFilter;

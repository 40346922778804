import { DisclosureInterface } from './disclosure';

export enum FileType {
  PDFSummary = 1,
  PDFGeneral = 2,
  XBRLGeneral = 3,
  JPEGGeneral = 4,
}

export interface DisclosedFileInterface {
  readonly id: number;
  readonly disclosureID: number;
  readonly version: number;
  readonly url: string;
  readonly fileType: FileType;
  readonly createdAt: Date;
}

export type WithFiles<T> = T & {
  readonly files: DisclosureInterface[];
};

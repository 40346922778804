import './tag-chips.module.scss';
import { DisclosureTag, useTags } from '../../libs/tags';
import { Box, Chip, Typography } from '@mui/material';
import { useFilter, useInstantFilter } from '../../libs/filter';
import {
  blue,
  brown,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@mui/material/colors';

/* eslint-disable-next-line */
export interface TagChipsProps {
  tagIDs: number[];
  filterOnClick?: boolean;
}

export function TagChips(props: TagChipsProps) {
  const { dict } = useTags();
  const tags = props.tagIDs.map((t) => dict[t]);
  const filter = useInstantFilter();

  const children = tags.filter(
    (t) => t.children.length === 0 && t.name !== 'その他'
  );
  const other = tags.filter((t) => t.name === 'その他')[0];

  const colors = [
    red,
    purple,
    indigo,
    blue,
    green,
    orange,
    brown,
    lime,
    teal,
    pink,
  ];
  const shades = [500, 200];

  const colorMap: string[] = [];
  for (let i = 0; i < 20; i++) {
    colorMap.push(colors[i % 10][shades[Math.floor(i / 10)]]);
  }

  const parentMap: Record<number, DisclosureTag[]> = {};
  for (const t of props.tagIDs) {
    const tag = dict[t];
    if (tag.parent) {
      if (tag.parentID in parentMap) {
        parentMap[tag.parentID].push(tag);
      } else {
        parentMap[tag.parentID] = [tag];
      }
    } else {
      parentMap[tag.id] = [];
    }
  }

  return (
    <>
      {Object.entries(parentMap).map(([k, v]) => {
        const parent = dict[k];
        if (v.length) {
          return (
            <Box
              key={k}
              sx={{
                borderRadius: '20px',
                border: '1px solid #ddd',
                pl: '8px',
                pr: '3px',
                py: '3px',
                display: 'inline-block',
                mr: '2px',
                mb: '2px',
                cursor: 'pointer',
                fontSize: 12
              }}
              onClick={
                props.filterOnClick
                  ? () =>
                      filter.updatePartial({
                        disclosure: {
                          tagIDs: parent.children.map((c) => c.id),
                        },
                      })
                  : undefined
              }
            >
              {parent.name}
              <Box sx={{ pl: '3px', display: 'inline-block' }}>
                {v.map((c) => (
                  <Chip
                    sx={{ ml: '2px' }}
                    label={c.name}
                    key={c.id}
                    size={'small'}
                    onClick={
                      props.filterOnClick
                        ? (e) => {
                            e.stopPropagation();
                            filter.setDisclosureCondition({
                              ...filter.condition.disclosure,
                              tagIDs: [c.id],
                            });
                          }
                        : undefined
                    }
                  />
                ))}
              </Box>
            </Box>
          );
        } else {
          return (
            <Chip
              sx={{ mb: '2px', mr: '2px' }}
              label={parent.name}
              key={k}
              size={'small'}
              onClick={
                props.filterOnClick
                  ? () =>
                      filter.setDisclosureCondition({
                        ...filter.condition.disclosure,
                        tagIDs: [parent.id],
                      })
                  : undefined
              }
            />
          );
        }
      })}
      {/*{children.map(c => <Chip  label={`${c.parent?.name}>${c.name}`} key={c.id} size={'small'} onClick={props.filterOnClick ? () => filter.setDisclosureCondition({...filter.condition.disclosure, tagIDs: [c.id]}) : undefined}/>)}*/}
      {/*{children.length === 0 && other && <Chip label={other.name} size={'small'} onClick={props.filterOnClick ? () => filter.setDisclosureCondition({...filter.condition.disclosure, tagIDs: [other.id]}) : undefined}/>}*/}
    </>
  );
}

export default TagChips;

import './page.module.scss';
import AppBar from '../app-bar/app-bar';
import { Box, Container } from '@mui/material';
import { useNotification } from '../../libs/notification';
import BottomBar from '../bottom-bar/bottom-bar';

/* eslint-disable-next-line */
export interface PageProps {}

export function Page({ children }: { children: JSX.Element }) {
  useNotification();
  return (
    <>
      <AppBar />
      <Container maxWidth={'xl'} sx={{ marginBottom: '90px' }}>
        {children}
      </Container>
      <BottomBar />
      {/*<Box width={'100%'} height={100}/>*/}
    </>
  );
}

export default Page;

import './filter-summary.module.scss';
import {
  FilterCondition,
  MapMarketCapitalString,
  MarketCapitalCondition,
  MarketCapitalConditionBoundary,
} from '../../libs/condition';
import { UpdatePartial } from '../../libs/filter';
import { Chip, withStyles } from '@mui/material';
import { useStocks } from '../../libs/stock';
import { industries, Industry17, Industry33 } from '@tedinet/data-access-stock';
import { DisclosureTag, useTags } from '../../libs/tags';
import { useWatchlist } from '../../libs/watchlist';

/* eslint-disable-next-line */
export interface FilterSummaryProps {
  deletable?: boolean;
  filter: FilterCondition;
  fillIfEmpty?: boolean;
  updatePartial: UpdatePartial;
}

export function FilterSummary(props: FilterSummaryProps) {
  const filter = props.filter;
  const { dict: stockDict, lastAvailableDict } = useStocks();
  const { children: tagChildren, parents: tagParents } = useTags();

  const chipGenerator = (
    label: string,
    onDelete: () => void,
    color:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'warning'
      | 'error'
      | 'info'
      | 'success' = 'default'
  ) =>
    label && (
      <Chip
        key={label}
        label={label}
        onDelete={props.deletable ? onDelete : undefined}
        variant={'outlined'}
        sx={{ mr: 1 }}
        size={'small'}
        color={color}
      />
    );

  const industry = industries.map((i) => {
    const checkedChildren = i.children.filter(
      (c) => filter.stock.industry[c.code]
    );
    return [i, i.children.length === checkedChildren.length, checkedChildren];
  });
  const industryAllChecked = industry.every(([_, allChecked]) => allChecked);
  const industryAllUnchecked = industry.every(
    ([_, __, checkedChildren]: [Industry17, boolean, Industry33[]]) =>
      checkedChildren.length === 0
  );

  const tags = tagParents.map((t) => {
    const checkedChildren = t.children.filter((c) =>
      filter.disclosure.tagIDs.includes(c.id)
    );
    return [
      t,
      t.children.length
        ? t.children.length === checkedChildren.length
        : filter.disclosure.tagIDs.includes(t.id),
      checkedChildren,
    ];
  });
  const tagAllChecked = tags.every(([_, allChecked]) => allChecked);
  const tagAllUnchecked = tags.every(
    ([parent, _, checkedChildren]: [DisclosureTag, boolean, DisclosureTag[]]) =>
      !filter.disclosure.tagIDs.includes(parent.id) &&
      checkedChildren.length === 0
  );

  const { dict: watchlistDict } = useWatchlist();

  return (
    <>
      {filter.global.keyword &&
        chipGenerator(`キーワード: ${filter.global.keyword}`, () =>
          props.updatePartial({ global: { keyword: '' } })
        )}
      {filter.stock.issueCodes.map((s) =>
        chipGenerator(
          `${s} ${
            (stockDict[s] ?? lastAvailableDict[s])?.name ?? '(名称取得失敗)'
          }`,
          () =>
            props.updatePartial({
              stock: {
                issueCodes: filter.stock.issueCodes.filter((c) => c !== s),
              },
            })
        )
      )}
      {filter.stock.watchlists.map((w) =>
        chipGenerator(
          `銘柄リスト: ${watchlistDict[w]?.name ?? '(削除中)'}`,
          () =>
            props.updatePartial({
              stock: {
                watchlists: filter.stock.watchlists.filter((wl) => wl !== w),
              },
            })
        )
      )}
      {Object.values(filter.stock.marketCapital).every((v) => !v) &&
        chipGenerator(
          '時価総額: 1つも選択されていません',
          () =>
            props.updatePartial({
              stock: {
                marketCapital: {
                  v50: true,
                  v50_100: true,
                  v100_300: true,
                  v300_1k: true,
                  v1k_3k: true,
                  v3k: true,
                },
              },
            }),
          'error'
        )}
      {Object.values(filter.stock.marketCapital).some((v) => !v) &&
        Object.entries(filter.stock.marketCapital)
          .sort(([k1], [k2]) => {
            return (
              MarketCapitalConditionBoundary[k1][0] -
              MarketCapitalConditionBoundary[k2][0]
            );
          })
          .map(([k, v]: [keyof MarketCapitalCondition, boolean]) => {
            if (v)
              return chipGenerator(
                `時価総額: ${MapMarketCapitalString[k]}`,
                () => {
                  if (
                    Object.values(filter.stock.marketCapital).reduce(
                      (c, m) => (m ? c + 1 : c),
                      0
                    ) > 1
                  )
                    props.updatePartial({
                      stock: {
                        marketCapital: {
                          ...filter.stock.marketCapital,
                          [k]: false,
                        },
                      },
                    });
                  else
                    props.updatePartial({
                      stock: {
                        marketCapital: {
                          v50: true,
                          v50_100: true,
                          v100_300: true,
                          v300_1k: true,
                          v1k_3k: true,
                          v3k: true,
                        },
                      },
                    });
                }
              );
          })}
      {industryAllUnchecked &&
        chipGenerator(
          '業種: 1つも選択されていません',
          () =>
            props.updatePartial({
              stock: {
                industry: Object.fromEntries(
                  Object.keys(filter.stock.industry).map((k) => [k, true])
                ),
              },
            }),
          'error'
        )}
      {!industryAllChecked &&
        industry.map(
          ([i, allChecked, children]: [Industry17, boolean, Industry33[]]) => {
            if (allChecked)
              return [
                chipGenerator(`業種: ${i.name}`, () => {
                  const f = {
                    ...filter.stock.industry,
                    ...Object.fromEntries(
                      i.children.map((c) => [c.code, false])
                    ),
                  };
                  if (Object.values(f).every((d) => !d)) {
                    props.updatePartial({
                      stock: {
                        industry: Object.fromEntries(
                          Object.keys(filter.stock.industry).map((k) => [
                            k,
                            true,
                          ])
                        ),
                      },
                    });
                  } else
                    props.updatePartial({
                      stock: {
                        industry: {
                          ...filter.stock.industry,
                          ...Object.fromEntries(
                            i.children.map((c) => [c.code, false])
                          ),
                        },
                      },
                    });
                }),
              ];
            return children.map((c) =>
              chipGenerator(`業種: ${c.name}`, () => {
                filter.stock.industry[c.code] = false;
                if (Object.values(filter.stock.industry).every((d) => !d)) {
                  props.updatePartial({
                    stock: {
                      industry: Object.fromEntries(
                        Object.keys(filter.stock.industry).map((k) => [k, true])
                      ),
                    },
                  });
                } else
                  props.updatePartial({
                    stock: {
                      industry: {
                        ...filter.stock.industry,
                      },
                    },
                  });
              })
            );
          }
        )}
      {filter.disclosure.keyword &&
        chipGenerator(`タイトルに含む: ${filter.disclosure.keyword}`, () =>
          props.updatePartial({
            disclosure: {
              keyword: '',
            },
          })
        )}
      {tagAllUnchecked &&
        chipGenerator(
          'タグ: 1つも選択されていません',
          () =>
            props.updatePartial({
              disclosure: { tagIDs: tagChildren.map((c) => c.id) },
            }),
          'error'
        )}
      {!tagAllChecked &&
        tags
          .map(
            ([t, allChecked, children]: [
              DisclosureTag,
              boolean,
              DisclosureTag[]
            ]) => {
              if (allChecked) {
                const childrenIDs = t.children.map((c) => c.id);
                return [
                  chipGenerator(`タグ: ${t.name}`, () => {
                    if (
                      (t.children.length > 0 &&
                        filter.disclosure.tagIDs.some(
                          (t) => !childrenIDs.includes(t)
                        )) ||
                      (t.children.length === 0 &&
                        filter.disclosure.tagIDs.length > 1)
                    )
                      props.updatePartial({
                        disclosure: {
                          tagIDs: t.children.length
                            ? filter.disclosure.tagIDs.filter(
                                (id) => !childrenIDs.includes(id)
                              )
                            : filter.disclosure.tagIDs.filter(
                                (id) => id !== t.id
                              ),
                        },
                      });
                    else if (!props.fillIfEmpty)
                      props.updatePartial({
                        disclosure: { tagIDs: [] },
                      });
                    else
                      props.updatePartial({
                        disclosure: { tagIDs: tagChildren.map((c) => c.id) },
                      });
                  }),
                ];
              }
              return children.map((c) =>
                chipGenerator(`タグ: ${c.name}`, () => {
                  if (filter.disclosure.tagIDs.length > 1)
                    props.updatePartial({
                      disclosure: {
                        tagIDs: filter.disclosure.tagIDs.filter(
                          (id) => id !== c.id
                        ),
                      },
                    });
                  else if (props.fillIfEmpty) {
                    props.updatePartial({
                      disclosure: { tagIDs: tagChildren.map((c) => c.id) },
                    });
                  } else {
                    props.updatePartial({
                      disclosure: { tagIDs: [] },
                    });
                  }
                })
              );
            }
          )
          .flat()}
    </>
  );
}

export default FilterSummary;

import './connectivity.module.scss';
import { useServerStatus } from '../../libs/server-status';
import { useSocketConnectionState } from '../../libs/socket';
import {
  ChangeCircle,
  CheckCircle,
  ReportGmailerrorred,
} from '@mui/icons-material';
import { UpstreamStatus } from '@tedinet/data-access-app';
import { Box, Typography } from '@mui/material';

/* eslint-disable-next-line */
export interface ConnectivityProps {}

export function Connectivity(props: ConnectivityProps) {
  const serverStatus = useServerStatus();
  const socketConnected = useSocketConnectionState();

  const iconProps = {};
  const cases = {
    realtime: {
      icon: <CheckCircle />,
      text: 'リアルタイム',
      color: 'white',
      background: 'transparent',
    },
    upstreamChecking: {
      icon: <ChangeCircle />,
      text: '配信状況確認中',
      color: 'orange',
      background: 'transparent',
    },
    upstreamDown: {
      icon: <ChangeCircle />,
      text: (
        <>
          配信状況確認中
          <br />
          更新が遅延している可能性があります。
        </>
      ),
      color: 'white',
      background: 'orange',
    },
    connecting: {
      icon: <ChangeCircle />,
      text: '接続中',
      color: 'orange',
      background: 'transparent',
    },
    networkError: {
      icon: <ReportGmailerrorred />,
      text: (
        <>
          接続に失敗しました。
          <br />
          ネットワークの状況を確認してください。
        </>
      ),
      color: 'white',
      background: 'red',
    },
    serverError: {
      icon: <ReportGmailerrorred />,
      text: (
        <>
          接続に失敗しました。
          <br />
          しばらくしてから再度お試しください。
        </>
      ),
      color: 'white',
      background: 'red',
    },
  };

  const status = (() => {
    if (socketConnected) {
      switch (serverStatus.upstreamStatus) {
        case UpstreamStatus.HEALTHY:
          return cases.realtime;
        case UpstreamStatus.UNKNOWN:
          return cases.upstreamChecking;
        case UpstreamStatus.UNHEALTHY:
          return cases.upstreamDown;
      }
    } else {
      if (serverStatus.healthy) return cases.connecting;
      if (serverStatus.isNetworkError) return cases.networkError;
      return cases.serverError;
    }
  })();

  return (
    <Box
      sx={{
        zIndex: 100,
        position: 'relative',
        display: 'flex',
        fontSize: 10,
        color: status.color,
        background: status.background,
        padding: status.background !== 'transparent' ? 1 : 0,
      }}
    >
      <Box mr={'2px'} sx={{ display: 'flex', alignItems: 'center' }}>
        {status.icon}
      </Box>
      <Typography>{status.text}</Typography>
    </Box>
  );
}

export default Connectivity;

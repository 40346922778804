import './tedinet-dialog.module.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

/* eslint-disable-next-line */
export interface TedinetDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element;
  actions: {label: string, onClick: () => void}[]
}

export function TedinetDialog(props: TedinetDialogProps) {
  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.actions.map((a, i) => <Button key={i} onClick={() => a.onClick()}>{a.label}</Button>)}
      </DialogActions>
    </Dialog>
  );
}

export default TedinetDialog;

import './search-stock.module.scss';
import { useStocks } from '../../libs/stock';
import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';
import { StockInterface } from '@tedinet/data-access-stock';
import { useEffect, useState } from 'react';
import {
  normalizeIssueCodeForSearch,
  normalizeStringForSearch,
} from '@tedinet/util-string-normalizer';

/* eslint-disable-next-line */
export interface SearchStockProps {
  onConfirm: (stock: StockInterface) => void;
  label?: string;
  disabled?: boolean;
}

export function SearchStock(props: SearchStockProps) {
  const { list: stocks, lastAvailableList } = useStocks();

  const [value, setValue] = useState<StockInterface | null>(null);

  useEffect(() => {
    if (value) {
      props.onConfirm(value);
      setValue(null);
    }
  }, [value]);

  const filterOptions = (options: StockInterface[], { inputValue }) => {
    const normalizedInput = normalizeStringForSearch(inputValue);
    const normalizedIssueCode = normalizeIssueCodeForSearch(inputValue);
    return options.filter(
      (s) =>
        s.issueCode.indexOf(normalizedIssueCode) > -1 ||
        s.nameForSearch.indexOf(normalizedInput) > -1
    );
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{props.label ?? '個別'}</FormLabel>
      <Autocomplete<StockInterface>
        renderInput={(params) => (
          <TextField {...params} placeholder="コードや会社名" />
        )}
        filterOptions={filterOptions}
        options={stocks?.length ? stocks : lastAvailableList}
        getOptionLabel={(option: StockInterface) =>
          `${option.issueCode} ${option.name}`
        }
        clearOnBlur
        autoHighlight
        clearIcon={null}
        noOptionsText={'見つかりません'}
        value={value}
        onChange={(e, newValue) => {
          setValue(typeof newValue === 'string' ? null : newValue);
        }}
        blurOnSelect
        disabled={props.disabled}
      />
    </FormControl>
  );
}

export default SearchStock;

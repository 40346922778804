import './disclosure-card.module.scss';
import {
  FileType,
  FullyJoinedDisclosure,
} from '@tedinet/data-access-disclosure';
import { Box, Divider, Link, Paper, Typography } from '@mui/material';
import { useStocks } from '../../libs/stock';
import IndustryChips from '../industry-chips/industry-chips';
import TagChips from '../tag-chips/tag-chips';

/* eslint-disable-next-line */
export interface DisclosureCardProps {
  disclosure: FullyJoinedDisclosure;
}

export function DisclosureCard(props: DisclosureCardProps) {
  const { dict: stockDict, lastAvailableDict } = useStocks();
  const issueCode = props.disclosure.issueCode.toString().slice(0, 4);
  const stock = stockDict[issueCode] ?? lastAvailableDict[issueCode];
  const stockName = stock?.name ?? '(名称取得失敗)';
  const general = props.disclosure.latestDisclosureVersion.files.find(
    (f) => f.fileType === FileType.PDFGeneral
  );
  return (
    <>
      <Paper sx={{ p: 1, mb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ display: 'inline-block', mr: 1 }} variant="body2">
            {issueCode} {stockName}
          </Typography>
          <Typography variant="body2">
            {props.disclosure.date}{' '}
            {props.disclosure.latestDisclosureVersion.time.slice(0, -3)}
          </Typography>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Link href={general?.url} target={'_blank'} sx={{ display: 'block' }}>
          {props.disclosure.latestDisclosureVersion.modification === 2 && (
            <Typography
              sx={{ color: 'red', display: 'inline-block', mr: 1 }}
              variant={'body2'}
            >
              {`${
                props.disclosure.latestDisclosureVersion.date
              } ${props.disclosure.latestDisclosureVersion.time.slice(
                0,
                -3
              )}`}{' '}
              訂正
            </Typography>
          )}
          {props.disclosure.latestDisclosureVersion.title}
        </Link>
        <IndustryChips
          industry33Code={stock?.industry33Code ?? 9999}
          filterOnClick
          xs
          prefix="業種: "
        />
        <Box sx={{ mt: 1 }}>
          <TagChips
            tagIDs={props.disclosure.latestDisclosureVersion.tagIDs}
            filterOnClick
          />
        </Box>
      </Paper>
    </>
  );
}

export default DisclosureCard;

import './versions-table.module.scss';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

export interface Column<T> {
  id: string;
  title: string;
  headerAlign?: 'center' | 'right' | 'left';
  align: 'center' | 'right' | 'left';
  valueGetter: (data: T) => unknown;
  formatter?: (value: unknown, data: T) => ReactNode;
  headerFilter?: JSX.Element;
  width?: number;
}

/* eslint-disable-next-line */
export interface TedinetTableProps<T> {
  data: T[];
  columns: Column<T>[];
  idColumn: string;
  direction?: 'ASC' | 'DESC';
  showHeaderFilter?: boolean;
}

export function TedinetTable<T>(props: TedinetTableProps<T>) {
  const getRow = (data: T) => {
    return (
      <>
        {props.columns.map((c) => {
          const value = c.valueGetter(data);
          return (
            <TableCell
              align={c.align ?? 'center'}
              key={c.id}
              style={{ width: c.width, backgroundColor: '#fff' }}
            >
              {c.formatter ? c.formatter(value, data) : (value as string)}
            </TableCell>
          );
        })}
      </>
    );
  };

  const getHeaderRow = (): JSX.Element => {
    return (
      <>
        {props.columns.map((c) => {
          return (
            <TableCell
              align={c.headerAlign ?? 'center'}
              key={c.id}
              style={{ width: c.width, backgroundColor: '#fff' }}
            >
              {c.title}
              {props.showHeaderFilter && c.headerFilter}
            </TableCell>
          );
        })}
      </>
    );
  };

  const width = props.columns.reduce((carry, count) => carry + count.width, 0);

  return (
    <>
      <Typography>{props.data.length} 件</Typography>
      <TableVirtuoso
        data={props.data}
        style={{ height: '85vh' }}
        components={{
          Table: (props) => <Table {...props} sx={{ width }} />,

          // fixedなヘッダーと被らないようにtopを設定
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          TableHead: (props) => (
            <TableHead
              {...props}
              style={{ ...(props as any).style, top: '40px' }}
            />
          ),

          TableRow: TableRow,
          // eslint-disable-next-line react/display-name
          TableBody: React.forwardRef((props, ref) => (
            <TableBody {...(props as any)} ref={ref} />
          )),
        }}
        useWindowScroll
        fixedHeaderContent={() => <TableRow>{getHeaderRow()}</TableRow>}
        itemContent={(index, row) => <>{getRow(row)}</>}
      />
      {/*<TableContainer>*/}
      {/*  <Table>*/}
      {/*    {getHeaderRow()}*/}
      {/*    <TableBody>*/}
      {/*      {props.data.slice(0, 200).map((_, i) => {*/}
      {/*        return getDataRow(props.direction === 'ASC' ? i : props.data.length - i - 1);*/}
      {/*      })}*/}
      {/*    </TableBody>*/}
      {/*  </Table>*/}
      {/*</TableContainer>*/}
    </>
  );
}

export default TedinetTable;

import './bottom-bar.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import NextLink from 'next/link';
import { useIsMobile } from '../../libs/is-mobile';

/* eslint-disable-next-line */
export interface BottomBarProps {}

export function BottomBar(props: BottomBarProps) {
  const isProduction = process.env.NODE_ENV === 'production';
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 5,
        background: 'rgba(255,255,255,0.56)',
        width: isMobile ? '100%' : 'auto',
      }}
    >
      <Grid
        container
        justifyContent={isMobile ? 'center' : 'flex-end'}
        spacing={1}
        sx={{ px: 1, py: '3px', position: 'relative', bottom: 0 }}
      >
        <Grid item>
          <NextLink href={isProduction ? '/terms.html' : '/terms'} passHref>
            <a target="_blank">
              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                当サービスについて
              </Typography>
            </a>
          </NextLink>
        </Grid>
        <Grid item>
          <NextLink href="/licenses.html" passHref>
            <a target="_blank">
              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                ライセンス情報
              </Typography>
            </a>
          </NextLink>
        </Grid>
        <Grid item>
          <NextLink href="https://argentumcode.co.jp" passHref>
            <a target="_blank">
              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                ©︎ ArGentumCode K.K.
              </Typography>
            </a>
          </NextLink>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BottomBar;

import { Box, Chip, Link, Typography } from '@mui/material';
import {
  DisclosedFileInterface,
  FileType,
  FullyJoinedDisclosureVersion,
} from '@tedinet/data-access-disclosure';
import { Sync } from '@mui/icons-material';

export interface FileLinkProps {
  version: FullyJoinedDisclosureVersion;
}

export function FileLink(props: FileLinkProps): JSX.Element {
  const { general, xbrl, image } = props.version.files.reduce(
    (
      p,
      c
    ): {
      summary: string | null;
      general: string | null;
      xbrl: string | null;
      image: string | null;
    } => {
      if (c.fileType === FileType.PDFSummary) p.summary = c;
      else if (c.fileType === FileType.PDFGeneral) p.general = c;
      else if (c.fileType === FileType.XBRLGeneral) p.xbrl = c;
      else if (c.fileType === FileType.JPEGGeneral) p.image = c;
      return p;
    },
    {
      summary: null,
      general: null,
      xbrl: null,
      image: null,
    }
  );
  // Use constant
  if (props.version.modification === 3) {
    return (
      <>
        <Typography
          sx={{ color: 'red', display: 'inline-block', mr: 1 }}
          variant={'body2'}
        >
          {`${props.version.date} ${props.version.time.slice(0, -3)}`} 削除
        </Typography>
        {props.version.title}
      </>
    );
  }

  if (!general?.url)
    return (
      <>
        <Sync fontSize={'small'} />
        {props.version.title}
      </>
    );
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Link href={general.url} target={'_blank'}>
          {props.version.modification === 2 && (
            <Typography
              sx={{ color: 'red', display: 'inline-block', mr: 1 }}
              variant={'body2'}
            >
              {`${props.version.date} ${props.version.time.slice(0, -3)}`} 訂正
            </Typography>
          )}
          {props.version.title}
        </Link>
      </Box>
      {[xbrl, image]
        .filter((f) => f)
        .map((f: DisclosedFileInterface) => {
          if (f.fileType === FileType.XBRLGeneral)
            return (
              <Link href={f.url} target={'_blank'} key={'xbrl'}>
                <Chip label="XBRL" size="small" sx={{ cursor: 'pointer' }} />
              </Link>
            );
          else if (f.fileType === FileType.JPEGGeneral)
            return (
              <Link href={f.url} target={'_blank'} key={'image'}>
                <Chip label="JPEG" size="small" sx={{ cursor: 'pointer' }} />
              </Link>
            );
        })}
    </Box>
  );
}

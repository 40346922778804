import './date-range-selector.module.scss';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Select,
  styled,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from '@mui/lab';
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import { Event } from '@mui/icons-material';
import { RangeType, useRange } from '../../libs/range';
import { useServerStatus } from '../../libs/server-status';

/* eslint-disable-next-line */
export interface DateRangeSelectorProps {}

export function DateRangeSelector(props: DateRangeSelectorProps) {
  const serverStatus = useServerStatus();
  const range = useRange();
  const { type, setType, dateStart, dateEnd } = range;
  const [date, setDate] = useState<Date>(
    serverStatus.date ? dayjs(serverStatus.date).toDate() : dayjs().toDate()
  );
  const [today, setToday] = useState<string>(
    serverStatus.date || dayjs().format('YYYY-MM-DD')
  );

  const [weeklyPickerAnchor, setWeeklyPickerAnchor] = useState(null);

  useEffect(() => {
    const d = dayjs(date);
    if (type === 'daily') {
      const s = d.format('YYYY-MM-DD');
      range.setDay(s);
      range.setDateStart(s);
      range.setDateEnd(s);
    } else if (type === 'weekly') {
      range.setDay(d.format('YYYY-MM-DD'));
      range.setDateStart(
        d.subtract(1, 'day').startOf('week').add(1, 'day').format('YYYY-MM-DD')
      );
      range.setDateEnd(
        d.subtract(1, 'day').endOf('week').add(1, 'day').format('YYYY-MM-DD')
      );
    }
  }, [date, type]);

  useEffect(() => {
    if (serverStatus?.date) {
      if (dayjs(date).format('YYYY-MM-DD') === today)
        setDate(dayjs(serverStatus.date).toDate());
      setToday(serverStatus.date);
    }
  }, [serverStatus?.date]);

  type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
  };

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
  })<CustomPickerDayProps>(
    ({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      }),
    })
  ) as React.ComponentType<CustomPickerDayProps>;

  const renderWeekPickerDay = (
    targetDate: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const day = dayjs(targetDate);
    const d = dayjs(date);
    const dayString = day.format('YYYY-MM-DD');
    const start = d.subtract(1, 'day').startOf('week').add(1, 'day');
    const end = d.subtract(1, 'day').endOf('week').add(1, 'day');

    const dayIsBetween = start <= day && day <= end;
    const isFirstDay = dayString === start.format('YYYY-MM-DD');
    const isLastDay = dayString === end.format('YYYY-MM-DD');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        // disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <Box sx={{ display: 'inline-block', mr: 1 }}>
      <Select
        variant="outlined"
        value={type}
        onChange={(e) => setType(e.target.value as RangeType)}
        // sx={{ width: 120 }}
        size={'small'}
      >
        <MenuItem value={'daily'}>日次</MenuItem>
        <MenuItem value={'weekly'}>週次</MenuItem>
        <MenuItem value={'flexible'} disabled>
          自由選択
        </MenuItem>
      </Select>
      {type === 'daily' && (
        <LocalizationProvider dateAdapter={DayjsAdapter}>
          <DatePicker
            inputFormat={'YYYY-MM-DD'}
            onChange={setDate}
            value={date}
            mask={'____-__-__'}
            renderInput={(params) => <TextField {...params} size={'small'} />}
          />
        </LocalizationProvider>
      )}
      {type === 'weekly' && (
        <>
          <TextField
            sx={{ width: 250 }}
            value={`${dateStart} ~ ${dateEnd}`}
            onClick={(e) => setWeeklyPickerAnchor(e.currentTarget)}
            InputProps={{
              size: 'small',
              endAdornment: (
                <IconButton
                  onClick={(e) => setWeeklyPickerAnchor(e.currentTarget)}
                >
                  <Event />
                </IconButton>
              ),
            }}
          />
          <Popover
            open={weeklyPickerAnchor !== null}
            anchorEl={weeklyPickerAnchor}
            onClose={() => setWeeklyPickerAnchor(null)}
          >
            <LocalizationProvider dateAdapter={DayjsAdapter}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Week picker"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderDay={renderWeekPickerDay}
                renderInput={(params) => (
                  <TextField {...params} size={'small'} />
                )}
                inputFormat="'Week of' MMM d"
              />
            </LocalizationProvider>
          </Popover>
        </>
      )}
    </Box>
  );
}

export default DateRangeSelector;
